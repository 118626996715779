<template>
  <div>
    <!-- Table -->
    <b-table responsive bordered :items="props.items" :fields="props.fields" class="mb-2">
      <!-- id -->
      <template #cell(id)="data">
        <div class="d-flex align-items-center">
          <div class="font-weight-bolder">
            {{ data.item.id }}
          </div>
        </div>
      </template>

      <!-- description -->
      <template #cell(description)="data">
        <div>
          <b-button pill size="sm" variant="primary" @click="$emit('desDetail', data.item)"
            v-b-modal.modal-scrollable-des>
            <span class="text-nowrap">Details</span>
          </b-button>
        </div>
      </template>

      <!-- categories -->
      <template #cell(categories)="data">
        <div>
          <span v-for="c in data.item.categories" :key="c.id">
            {{ c.name }} ,
          </span>
        </div>
      </template>

      <!-- tags -->
      <template #cell(tags)="data">
        <div>
          <span v-for="t in data.item.tags" :key="t.id">
            {{ t.name }} ,
          </span>
        </div>
      </template>

      <!-- image -->
      <template #cell(photo_url)="data">
        <div class="" v-if="data.item.photo">
          <img :src="data.item.photo.name" class="tphoto" alt="photo" />
        </div>
      </template>
      <template #cell(images)="data">
        <div>
          <b-button pill size="sm" variant="primary" @click="$emit('photosDetails', data.item)"
            v-b-modal.modal-scrollable-photos>
            <span class="text-nowrap">Details</span>
          </b-button>
        </div>
      </template>

      <!-- promotion -->
      <!-- promo img -->
      <template #cell(photo_url_promo)="data">
        <div class="" v-if="data.item.media">
          <img :src="data.item.media.name" class="tphoto" alt="promotion photo" />
        </div>
      </template>
      <!-- promo url -->
      <template #cell(promo_url)="data">
        <div class="">
          <a :href="data.item.url" target="_blank">{{ data.item.url }}</a>
        </div>
      </template>

      <!-- tickets -->
      <template #cell(tickets)="data">
        <div>
          <b-button pill size="sm" variant="primary" v-b-modal.modal-scrollable>
            <span class="text-nowrap">Details</span>
          </b-button>
        </div>
      </template>
      <!-- tickets status -->
      <template #cell(ticket_status_id)="data">
        <div class="d-flex justify-content-between align-items-center" v-if="data.item.ticket_status_id === 1">
          <b-button pill size="sm" variant="warning" v-b-modal.modal-center-pending @click="$emit('editFunc', data.item)">
            <span class="text-nowrap">Pending <feather-icon icon="ClockIcon" /></span>
          </b-button>
        </div>

        <div class="d-flex justify-content-between align-items-center" v-if="data.item.ticket_status_id === 2">
          <b-button pill size="sm" variant="success" v-b-modal.modal-center-pending @click="$emit('editFunc', data.item)">
            <span class="text-nowrap">Approved <feather-icon icon="CheckCircleIcon" />
            </span>
          </b-button>
        </div>

        <div class="d-flex justify-content-between align-items-center" v-if="data.item.ticket_status_id === 3">
          <b-button pill size="sm" variant="danger" v-b-modal.modal-center-pending @click="$emit('editFunc', data.item)">
            <span class="text-nowrap">Declined <feather-icon icon="XCircleIcon" />
            </span>
          </b-button>
        </div>
      </template>

      <!-- review rating -->
      <template #cell(rating)="data">
        <div>
          <b-form-rating v-model="data.item.rating" readonly variant="primary"></b-form-rating>
        </div>
      </template>

      <!-- bookings status -->
      <template #cell(booking_status_id)="data">
        <div class="d-flex justify-content-between align-items-center" v-if="data.item.booking_status_id === 1">
          <b-button pill size="sm" variant="warning" v-b-modal.modal-center-pending
            @click="$emit('bookingEditFunc', data.item)">
            <span class="text-nowrap">Pending <feather-icon icon="ClockIcon" /></span>
          </b-button>
        </div>

        <div class="d-flex justify-content-between align-items-center" v-if="data.item.booking_status_id === 2">
          <b-button pill size="sm" variant="success" v-b-modal.modal-center-pending
            @click="$emit('bookingEditFunc', data.item)">
            <span class="text-nowrap">Approved <feather-icon icon="CheckCircleIcon" />
            </span>
          </b-button>
        </div>

        <div class="d-flex justify-content-between align-items-center" v-if="data.item.booking_status_id === 3">
          <b-button pill size="sm" variant="danger" v-b-modal.modal-center-pending
            @click="$emit('bookingEditFunc', data.item)">
            <span class="text-nowrap">Declined <feather-icon icon="XCircleIcon" />
            </span>
          </b-button>
        </div>
      </template>

      <!-- state -->
      <!-- state location -->
      <template #cell(state_location)="data">
        <div>
          <b-button pill size="sm" variant="primary" @click="$emit('getStateLatLng', data.item)"
            v-b-modal.modal-scrollable-state>
            <span class="text-nowrap">View State</span>
          </b-button>
        </div>
      </template>

      <!-- Actions -->
      <template #cell(actions)="data">
        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
          <template #button-content>
            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
          </template>

          <router-link v-if="isService || isEvent" :to="{
            name: routeName,
            query: { id: data.item.id },
          }" class="dropdown-item-con">
            <feather-icon icon="BookOpenIcon" />
            <span class="align-middle ml-50">Details</span>
          </router-link>

          <router-link v-if="(!isMenu && !isCupon && !isReview) && (isService || isEvent || !isNews)" :to="{
            name: `add-edit-${pageName}`,
            query: {
              isService,
              isEvent,
              isNews,
              isEdit: true,
              editDatas: isEvent ? data.item.id : JSON.stringify(data.item),
              categories: JSON.stringify(categories),
              states: JSON.stringify(states),
              users: JSON.stringify(users),
              tags: JSON.stringify(tags),
            },
          }" class="dropdown-item-con">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </router-link>
          <router-link v-else :to="{name : 'EditBlog' , params : {id : data.item.id}}" class="dropdown-item-con">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </router-link>

          <b-dropdown-item v-if="!isMenu && !isCupon && !isReview && !isService && !isEvent && !isNews"
            @click="$emit('editFunc', data.item)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <b-dropdown-item v-if="isMenu || isCupon || isReview"
            @click="$emit('editFunc', isMenu, isCupon, isReview, data.item)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <!-- <b-dropdown-item v-if="!isMenu && !isCupon && !isReview && !isService && !isEvent && !isNews"
            @click="$emit('delFunc', data.item.id)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item> -->
          <b-dropdown-item v-if="!isMenu && !isCupon && !isReview" @click="$emit('delFunc', data.item.id)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>

          <b-dropdown-item v-if="isMenu || isCupon || isReview" @click="$emit(
            'delFunc',
            isMenu
              ? 'menus'
              : isCupon
                ? 'cupons'
                : isReview
                  ? 'reviews'
                  : '',
            data.item.id
          )
            ">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <!-- Pagination -->
    <template v-if="isService || isEvent || isNews">
      <div class="float-right mr-5">
        <MyPagination :fetchDatas="fetchDatas" :pageTotal="pageTotal" :pageName="pageName" :pageNum="props.pageNum" />
      </div>
    </template>
  </div>
</template>
    
<script>
import { ref } from "@vue/composition-api";
import MyPagination from "./MyPagination.vue";
import {
  BTable,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormRating,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormRating,
    MyPagination,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    states: {
      type: Array,
      default: () => [],
    },
    users: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: Array,
      default: () => [],
    },
    isService: {
      type: Boolean,
      default: false,
    },
    isEvent: {
      type: Boolean,
      default: false,
    },
    isNews: {
      type: Boolean,
      default: false,
    },
    routeName: {
      type: String,
      default: "",
    },
    isMenu: {
      type: Boolean,
      default: false,
    },
    isCupon: {
      type: Boolean,
      default: false,
    },
    isReview: {
      type: Boolean,
      default: false,
    },
    pageName: {
      type: String,
      default: "",
    },
    pageTotal: {
      type: Number,
    },
    pageNum: {
      type: [Number, String, Array],
      default: [1, "1", () => []],
    },
    fetchDatas: {
      type: Function,
    },
  },
  setup(props, { emits }) {
    return {
      props,
      emits,
    };
  },
};
</script>

<style scoped>
.dropdown-item-con {
  display: block;
  font-weight: 400;
  color: #6e6b7b;
  padding: 0.65rem 1.28rem;
}

.dropdown-item-con:hover {
  color: #7367f0;
}

.tphoto {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}
</style>